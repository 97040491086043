import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../app/store";
import { toggleDeviceStatus } from "./devicesSlice";
import { Box, Typography, Switch, FormControlLabel, Tabs, Tab } from "@mui/material";
import DeviceTabs from "./DeviceTabs";
import DeviceInfo from "./DeviceInfo";

const DeviceDetails = () => {
  return (
    <Box padding={3}>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          data-testid="content_wrapper"
        >
          <Box data-testid="tab_wrapper">
            <DeviceTabs />
          </Box>
        </Box>
        <DeviceInfo />
      </Box>
    </Box>
  );
};

export default DeviceDetails;
