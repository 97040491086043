import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import StateAndControl from "./StateAndControlTab";

interface DeviceInfoProps {}

const DeviceInfo: React.FC<DeviceInfoProps> = () => {
  const device = useSelector((state: RootState) => state.devices.selectedDevice);
  const selectedTab = useSelector((state: RootState) => state.devices.selectedDevice?.selectedTab);

  const renderContentByTab = () => {
    switch (selectedTab) {
      case 0:
        return <StateAndControl />;

      case 1:
        return <Typography variant="h6">Модуль статистика в стадии разработк.</Typography>;

      case 2:
        return <Typography variant="h6">Модуль Графики в сталии разработки.</Typography>;

      default:
        return <Typography variant="h6">Необходимо выбрать устройство</Typography>;
    }
  };

  return (
    <Box marginTop={2}>
      {device ? (
        renderContentByTab()
      ) : (
        <Typography variant="h6">Нет выбранного устройства</Typography>
      )}
    </Box>
  );
};

export default DeviceInfo;
