import { Box, Typography, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { useTheme } from "@mui/material/styles";

const AccountCard = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      padding={2}
      width="100%"
      borderRadius="8px"
      bgcolor={theme.palette.mode === "dark" ? "#424242" : "#f5f5f5"}
    >
      <Avatar sx={{ marginRight: 2 }}>{user ? user.toUpperCase() : "A"}</Avatar>
      <Typography variant="body1" color={theme.palette.mode === "dark" ? "#fff" : "#000"}>
        {user}
      </Typography>
    </Box>
  );
};

export default AccountCard;
