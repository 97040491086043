import React from "react";
import { Box, Typography, Grid, FormControlLabel, Switch } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { toggleDeviceStatus } from "./devicesSlice";

interface StateAndControlProps {}

const StateAndControl: React.FC<StateAndControlProps> = () => {
  const device = useSelector((state: RootState) => state.devices.selectedDevice);
  const dispatch = useDispatch();

  const handleToggle = () => {
    dispatch(toggleDeviceStatus());
  };
  return (
    <Box marginTop={2}>
      {device ? (
        <Grid container spacing={2}>
          {/* Левый столбец: Состояние */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Состояние</Typography>

            <Box>
              <Typography>Устройство ID: {device.id}</Typography>
              <Typography>Статус: {device.status}</Typography>
            </Box>
          </Grid>

          {/* Правый столбец: Управление */}
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Управление</Typography>
            <Box>
              {device ? (
                <>
                  <Typography variant="h4">Device {device.id}</Typography>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={device.status === "on"}
                        onChange={handleToggle}
                        name="deviceStatus"
                      />
                    }
                    label="Включить / Выключить"
                  />
                </>
              ) : (
                <Typography variant="h6">Выберите устройство</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h6">Нет выбранного устройства</Typography>
      )}
    </Box>
  );
};

export default StateAndControl;
